export default {
  "menu_index": "Accueil",
  "menu_about": "À propos de nous",
  "menu_team": "Équipe professionnelle",
  "menu_business": "Domaine d'activité",
  "menu_case": "Soumission de cas",
  "menu_info": "Informations juridiques",
  "menu_success": "Cas de succès",
  "menu_language": "Changement de langue",
  "Integrity": "Intégrité",
  "major": "Majeur",
  "responsibility": "Responsabilité",
  "efficiency": "Efficacité",
  "index_intro1": "Nous offrons à nos clients",
  "index_intro2": "une gamme complète de services juridiques basés sur",
  "index_intro2_orange": "professionnalisme",
  "index_intro3_orange": "efficacité",
  "index_intro3_white": "et",
  "index_intro3_orange2": "innovation.",
  "elite_professional": "Cabinet d'avocats d'élite professionnel",
  "get_in_touch": "Contactez-nous",
  "about_sub": "À PROPOS DE NOUS",
  "law_name": "Will Lance Law Firm",
  "about_intro1": "Notre équipe d'avocats est expérimentée dans le traitement des cas de fraude, de localisation et de récupération d'actifs, aidant nos clients à récupérer les actifs et l'argent perdus à la suite de fraudes, de mensonges ou de malhonnêteté. Nous développons des stratégies efficaces pour retracer les actifs.",
  "about_intro2": "Nous sommes spécialisés dans le traitement des crimes liés à la cybernétique, y compris les escroqueries d'investissement dans les fonds communs de placement, les escroqueries liées aux cryptomonnaies, les escroqueries sentimentales, les escroqueries liées à des emplois à temps partiel, et plus encore.",
  "about_intro3": "En particulier, nous traitons les crimes informatiques liés à la fraude aux fonds, à la détournement d'argent, à la fraude sociale et au crime à temps partiel.",
  "year": "Année",
  "established": "Établi",
  "number_of_customers_served": "Nombre de clients servis",
  "service_case": "Cas de service",
  "people": "Personnes",
  "senior_consultant": "Consultant principal",
  "professional_body": "Organisme professionnel",
  "team_sub": "ÉQUIPE",
  "anti_fraud_information": "Informations anti-fraude",
  "anti_sub": "Actualités et informations",
  "more": "PLUS",
  "swiper_text1": "Intégrité, professionnalisme, Responsabilité, Efficacité",
  "swiper_text2": "Les Cabinets d'Avocats Will Lance s'engagent à",
  "swiper_text3": "Construire un cabinet d'avocats de haute qualité et professionnel",
  "professional_team": "Équipe professionnelle",
  "business_tip": "L'honnêteté et la fiabilité, la diligence et le sérieux sont les fondements du Will Lance Law Firm ; être pragmatique et respectueux de la loi et atteindre les objectifs sont les poursuites incessantes du Will Lance Law Firm",
  "scope1": "Escroquerie en investissement",
  "scope2": "Conflits économiques",
  "scope3": "Affaires juridiques d'entreprise",
  "scope4": "Avocat privé",
  "scope5": "Contentieux financier",
  "scope6": "Droit de la concurrence",
  "scope7": "Recouvrement de créances",
  "scope8": "Autres entreprises",
  "case_consultation": "Consultation de cas",
  "case_inquiry": "Enquête de cas",
  "consultation_sub": "Votre avis est la force motrice de notre avancement",
  "case_type": "Type de cas",
  "select_scam_type": "Sélectionnez le type d'arnaque",
  "your_case": "Votre cas",
  "please_enter_feedback": "Veuillez saisir votre avis sur le cas",
  "your_phone": "Votre numéro de téléphone portable",
  "please_enter_your_phone": "Veuillez saisir votre numéro de téléphone",
  "submit_click": "Soumettre en un clic",
  "please_enter_phone": "Veuillez saisir un numéro de téléphone",
  "search": "rechercher",
  "no_relevant": "Aucune réponse pertinente trouvée",
  "success_title": "Histoires de succès classiques",
  "bot_tele": "Téléphone",
  "bot_address": "Adresse",
  "bot_cooperation": "Agence d'autorisation de coopération",
  "quick_links": "Liens rapides",
  "CFTC": "Les régulateurs financiers internationaux, y compris la Commission de négociation des contrats à terme (CFTC)",
  "FINRA": "FINRA",
  "FINMA": "Autorité suisse de surveillance des marchés financiers (FINMA)",
  "FCA": "Autorité de conduite financière du Royaume-Uni (FCA)",
  "HKMA": "Autorité monétaire de Hong Kong (HKMA)",
  "CFPB": "Bureau de protection financière des consommateurs (CFPB)",
  "case_option1": "Arnaque à la collecte de fonds sur Internet",
  "case_option2": "Fraude à l'investissement dans les fonds communs de placement sur Internet",
  "case_option3": "Arnaque aux cryptomonnaies",
  "case_option4": "Arnaque amoureuse",
  "case_option5": "Fraude dans le commerce électronique",
  "case_option6": "Arnaque à temps partiel",
  "case_option7": "Autres escroqueries",
  "case_detail": "Détails du cas",
  "reply_message": "Message de réponse",
  "submission_time": "Heure de soumission",
  "home_service": "Service client"
}
export default {
  "menu_index": "Trang chủ",
  "menu_about": "Giới thiệu về chúng tôi",
  "menu_team": "Đội ngũ chuyên nghiệp",
  "menu_business": "Lĩnh vực kinh doanh",
  "menu_case": "Nộp hồ sơ",
  "menu_info": "Thông tin pháp lý",
  "menu_success": "Các trường hợp thành công",
  "menu_language": "Chuyển đổi ngôn ngữ",
  "Integrity": "Chính trực",
  "major": "Chuyên môn",
  "responsibility": "Trách nhiệm",
  "efficiency": "Hiệu quả",
  "index_intro1": "Chúng tôi đang cung cấp cho khách hàng của mình",
  "index_intro2": "với một loạt các dịch vụ pháp lý dựa trên",
  "index_intro2_orange": "Chuyên nghiệp",
  "index_intro3_orange": "Hiệu quả",
  "index_intro3_white": " và",
  "index_intro3_orange2": " đổi mới.",
  "elite_professional": "Văn phòng Luật sư Chuyên nghiệp Ưu tú",
  "get_in_touch": "Liên hệ với chúng tôi",
  "about_sub": "GIỚI THIỆU",
  "law_name": "Will Lance Law Firm",
  "about_intro1": "Đội ngũ luật sư của chúng tôi có kinh nghiệm trong việc xử lý các vụ gian lận, theo dõi tài sản và khôi phục các vụ, hỗ trợ khách hàng của chúng tôi khôi phục lại tài sản và tiền bị mất do gian lận, nói dối hoặc không trung thực. Chúng tôi phát triển các chiến lược hiệu quả để theo dõi tài sản.",
  "about_intro2": "Chúng tôi chuyên xử lý các tội phạm liên quan đến internet, bao gồm các vụ lừa đảo đầu tư quỹ cổ phần, các vụ lừa đảo tiền điện tử, các vụ lừa đảo mối quan hệ, các vụ lừa đảo công việc bán thời gian và nhiều hơn nữa.",
  "about_intro3": "Đặc biệt, chúng tôi xử lý các tội phạm máy tính liên quan đến gian lận quỹ, biển đảo tiền, gian lận xã hội và tội phạm bán thời gian.",
  "year": "Năm",
  "established": "Thành lập",
  "number_of_customers_served": "Số lượng khách hàng đã được phục vụ",
  "service_case": "Trường hợp dịch vụ",
  "people": "Mọi người",
  "senior_consultant": "Tư vấn cấp cao",
  "professional_body": "Tổ chức chuyên nghiệp",
  "team_sub": "ĐỘI NGŨ",
  "anti_fraud_information": "Thông tin chống gian lận",
  "anti_sub": "Tin tức và thông tin",
  "more": "XEM THÊM",
  "swiper_text1": "Chính trực, chuyên nghiệp, Trách nhiệm, Hiệu quả",
  "swiper_text2": " Will Lance Law Firm cam kết",
  "swiper_text3": " Xây dựng một văn phòng luật sư chất lượng cao và chuyên nghiệp",
  "professional_team": "Đội ngũ chuyên nghiệp",
  "business_tip": "Chân thành và đáng tin cậy, chăm chỉ và chu đáo là nền tảng của Will Lance Law Firm; pragmatism và tuân thủ pháp luật và đạt được mục tiêu là sự theo đuổi không ngừng nghỉ của Will Lance Law Firm",
  "scope1": "Lừa đảo đầu tư",
  "scope2": "Tranh chấp kinh tế",
  "scope3": "Các vấn đề pháp lý doanh nghiệp",
  "scope4": "Luật sư riêng",
  "scope5": "Tranh chấp tài chính",
  "scope6": "Luật chống độc quyền",
  "scope7": "Thu nợ",
  "scope8": "Kinh doanh khác",
  "case_consultation": "Tư vấn trường hợp",
  "case_inquiry": "Yêu cầu trường hợp",
  "consultation_sub": "Ý kiến của bạn là động lực để chúng tôi tiến lên phía trước",
  "case_type": "Loại trường hợp",
  "select_scam_type": "Chọn loại lừa đảo",
  "your_case": "Trường hợp của bạn",
  "please_enter_feedback": "Vui lòng nhập ý kiến của bạn về trường hợp",
  "your_phone": "Số điện thoại di động của bạn",
  "please_enter_your_phone": "Vui lòng nhập số điện thoại của bạn",
  "submit_click": "Gửi với một cú nhấp chuột",
  "please_enter_phone": "Vui lòng nhập số điện thoại",
  "search": "tìm kiếm",
  "no_relevant": "Không có câu trả lời liên quan được tìm thấy",
  "success_title": "Các câu chuyện thành công cổ điển",
  "bot_tele": "Điện thoại",
  "bot_address": "Địa chỉ",
  "bot_cooperation": "Cơ quan ủy quyền hợp tác",
  "quick_links": "Liên kết nhanh",
  "CFTC": "Các cơ quan quản lý tài chính quốc tế bao gồm Ủy ban Giao dịch Hàng hóa và Chứng khoán Tương lai (CFTC)",
  "FINRA": "FINRA (FINRA)",
  "FINMA": "Cơ quan Giám sát Thị trường Tài chính Thụy Sĩ (FINMA)",
  "FCA": "Ủy ban Hành vi Tài chính Vương quốc Anh (FCA)",
  "HKMA": "Ủy ban Tiền tệ Hồng Kông (HKMA)",
  "CFPB": "Cơ quan Bảo vệ Người tiêu dùng Tài chính (CFPB)",
  "case_option1": "Kế hoạch gây quỹ trên Internet",
  "case_option2": "Lừa đảo đầu tư quỹ cổ phần trực tuyến",
  "case_option3": "Lừa đảo tiền điện tử",
  "case_option4": "Lừa đảo tình yêu",
  "case_option5": "Lừa đảo thương mại điện tử",
  "case_option6": "Lừa đảo công việc bán thời gian",
  "case_option7": "Các loại lừa đảo khác",
  "case_detail": "Chi tiết trường hợp",
  "reply_message": "Thông điệp trả lời",
  "submission_time": "Thời gian gửi"
}
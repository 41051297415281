export default {
  "login": "Log In",
  'daily-eth': 'Daily ETH Rewards for Owning Virtual Miners',
  'world-first': "World's first Virtual Miner that mines ETH every day",
  'start-earn': 'Start Earning',
  'how-daily': 'How Daily Returns Work?',
  'signup': 'Sign up',
  'easi-signup': 'Easily sign up on the web or app, confirm your wallet, and start earning.',
  'virtual-miner': 'Virtual Miner Purchase',
  'choose-virtual-miner': 'Choose a Virtual Miner, invest with any payment method, and get started.',
  'dialy-returns': 'Daily Returns',
  'dialy-eth-returns': 'Daily ETH returns directly to your wallet.',
  'how-to-start': 'How to Start?',
  '1-start-1': 'Purchase a Virtual Miner ',
  '1-start-2': 'with as low as 50$.',
  '2-start-1': 'Complete registration ',
  '2-start-2': 'and connect your wallet.',
  '3-start-1': 'Start earning daily ' ,
  '3-start-2': 'ETH rewards.',
  'explore-the-gomining': 'Explore the Gomining Platform',
  'explore-1-1': 'Educational ',
  'explore-1-2': 'Resources',
  'explore-2-1': 'Market ',
  'explore-2-2': 'Insights',
  'explore-3-1': 'Virtual Miner ',
  'explore-3-2': 'Game',
  'invest-safely-1': 'Invest Safely with ',
  'invest-safely-2': 'GoMining – Guaranteed',
  'invest-safely-tip-1': "GoMining's activity is supported by a global infrastructure ",
  'invest-safely-tip-2': "and a team of professionals working to ensure the stability ",
  'invest-safely-tip-3': "and growth of the platform",
  'secure-reliable': 'Secure and reliable mining operations',
  'amount-hashrate': 'Amount of Hashrate Sold',
  'total-sales': 'Total Sales Milestone',
  'easily-deposit-1': 'Easily Deposit and ',
  'easily-deposit-2': 'Withdraw Rewards',
  'no-commission': 'No commissions. Use trusted means of payment',
  'get-start-free-1': 'Get Started ',
  'get-start-free-2': 'for Free Now',
  'start-earn-eth': 'Start Earning Ethereum with Gomining’s Virtual Miners',
  'start-earn-today': 'Start Earning Today',
  'bot-1': 'Investments are kept in secure wallets with trusted partners ',
  'bot-2': 'The connection is protected by SSL certified 256 bit Secure Processing'
}
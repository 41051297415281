export default {
  "menu_index": "首頁",
  "menu_about": "關於我們",
  "menu_team": "專業團隊",
  "menu_business": "業務範圍",
  "menu_case": "案例提交",
  "menu_info": "法律信息",
  "menu_success": "成功案例",
  "menu_language": "語言切換",
  "Integrity": "誠信",
  "major": "專業",
  "responsibility": "責任",
  "efficiency": "效率",
  "index_intro1": "我們以",
  "index_intro2": "基於",
  "index_intro2_orange": "專業，誠信",
  "index_intro3_orange": "高效，創新",
  "index_intro3_white": "的精神為基礎",
  "index_intro3_orange2": "致力於為客戶提供全方位的法律服務",
  "elite_professional": "精英專業律所",
  "get_in_touch": "聯絡我們",
  "about_sub": "關於我們",
  "law_name": "Will Lance Law Firm",
  "about_intro1": "我們的律師團隊在處理欺詐、資產追踪和追回案件方面經驗豐富，協助客戶追回因欺詐、謊言或不誠實而丟失的資產和金錢。我們制定有效的策略來追踪資產。",
  "about_intro2": "我們專注於處理與網絡犯罪相關的案件，包括股份基金投資詐騙、加密貨幣詐騙、感情詐騙、兼職工作詐騙等。",
  "about_intro3": "特別是，我們處理與資金詐騙、挪用資金、社會欺詐和兼職犯罪相關的電腦犯罪。",
  "year": "年",
  "established": "成立",
  "number_of_customers_served": "服務客戶數量",
  "service_case": "服務案例",
  "people": "人",
  "senior_consultant": "資深顧問",
  "professional_body": "專業機構",
  "team_sub": "團隊",
  "anti_fraud_information": "反詐騙信息",
  "anti_sub": "新聞和信息",
  "more": "更多",
  "swiper_text1": "誠信、專業、責任、效率",
  "swiper_text2": "Will Lance Law Firm致力於",
  "swiper_text3": "建立一個高質量和專業的律所",
  "professional_team": "專業團隊",
  "business_tip": "誠實守信、勤奮盡責是Will Lance Law Firm的基石；務實守法、達成目標是Will Lance Law Firm不懈追求的目標",
  "scope1": "投資詐騙",
  "scope2": "經濟爭端",
  "scope3": "公司法律事務",
  "scope4": "私人律師",
  "scope5": "金融訴訟",
  "scope6": "反壟斷法",
  "scope7": "債務收集",
  "scope8": "其他業務",
  "case_consultation": "案例諮詢",
  "case_inquiry": "案例查詢",
  "consultation_sub": "您的意見是我們前進的動力",
  "case_type": "案例類型",
  "select_scam_type": "選擇詐騙類型",
  "your_case": "您的案件",
  "please_enter_feedback": "請輸入您的案件反饋",
  "your_phone": "您的手機號",
  "please_enter_your_phone": "請輸入您的手機號",
  "submit_click": "一鍵提交",
  "please_enter_phone": "請輸入手機號",
  "search": "搜索",
  "no_relevant": "未找到相關回覆",
  "success_title": "經典成功案例",
  "bot_tele": "電話",
  "bot_address": "地址",
  "bot_cooperation": "合作授權機構",
  "quick_links": "快速鏈接",
  "CFTC": "國際金融監理機構包括商品與期貨交易委員會 （CFTC）",
  "FINRA": "美國金融業監管局（FINRA）",
  "FINMA": "瑞士金融市場監督管理局（FINMA）",
  "FCA": "英國金融行為監管局（FCA）",
  "HKMA": "香港金融管理局（HKMA）",
  "CFPB": "美國消費者金融保護局（CFPB）",
  "case_option1": "網路募捐詐騙",
"case_option2": "網路股票基金投資詐騙",
"case_option3": "加密貨幣詐騙",
"case_option4": "情感詐騙",
"case_option5": "電子商務詐騙",
"case_option6": "兼職詐騙",
"case_option7": "其他詐騙",
"case_detail": "案例詳情",
"reply_message": "回覆訊息",
"submission_time": "提交時間",
["home_service"]: "在線客服"
}
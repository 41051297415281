export default {
  "menu_index": 'Indice',
  'menu_about': 'Chi siamo',
  'menu_team': 'Team professionale',
  'menu_business': 'Ambito commerciale',
  'menu_case': 'Presentazione del caso',
  'menu_info': 'Informazioni legali',
  'menu_success': 'Caso di successo',
  'menu_language': 'Cambio lingua',
  'Integrity': 'Integrità',
  'major': 'Maggiore',
  'responsibility': 'Responsabilità',
  'efficiency': 'Efficienza',
  'index_intro1': 'Offriamo ai nostri clienti',
  'index_intro2': 'una vasta gamma di servizi legali basati su',
  'index_intro2_orange': 'Professionalità',
  'index_intro3_orange': 'Efficienza',
  'index_intro3_white': ' e',
  'index_intro3_orange2': ' innovazione.',
  'elite_professional': 'Studio legale di élite professionale',
  'get_in_touch': 'Contattaci',
  'about_sub': 'CHI SIAMO',
  'law_name': 'Studio Legale Will Lance',
  'about_intro1': 'Il nostro team di avvocati è esperto nel trattare casi di frode, tracciamento e recupero di attività, assistendo i nostri clienti nel recuperare attività e denaro persi a causa di frodi, bugie o disonestà. Sviluppiamo strategie efficaci per tracciare le attività.',
  'about_intro2': 'Siamo specializzati nel trattare crimini informatici, inclusi truffe di investimento in fondi comuni, truffe con criptovalute, truffe sentimentali, truffe di lavoro part-time e altro ancora.',
  'about_intro3': "In particolare, ci occupiamo di reati informatici legati alla frode finanziaria, all'appropriazione indebita di denaro, alla frode sociale e al crimine part-time.",
  'year': 'Anno',
  'established': 'Fondato',
  'number_of_customers_served': 'Numero di clienti serviti',
  'service_case': 'Caso di servizio',
  'people': 'Persone',
  'senior_consultant': 'Consulente senior',
  'professional_body': 'Organismo professionale',
  'team_sub': 'TEAM',
  'anti_fraud_information': 'Informazioni anti-frode',
  'anti_sub': 'Notizie e informazioni',
  'more': 'ALTRO',
  'swiper_text1': 'Integrità, professionalità, responsabilità, efficienza',
  'swiper_text2': 'Lo Studio Legale Will Lance si impegna a',
  'swiper_text3': 'Costruire uno studio legale di alta qualità e professionale',
  'professional_team': 'Team professionale',
  'business_tip': 'Onestà e affidabilità, diligenza e coscienziosità sono le fondamenta dello Studio Legale Will Lance; essere pragmatici e rispettosi della legge e raggiungere gli obiettivi sono il costante perseguimento dello Studio Legale Will Lance',
  'scope1': 'Truffa agli investimenti',
  'scope2': 'Controversie economiche',
  'scope3': 'Affari legali aziendali',
  'scope4': 'Avvocato privato',
  'scope5': 'Contenzioso finanziario',
  'scope6': 'Diritto antitrust',
  'scope7': 'Recupero crediti',
  'scope8': 'Altri affari',
  'case_consultation': 'Consulenza sul caso',
  'case_inquiry': 'Richiesta di caso',
  'consultation_sub': 'La tua opinione è la forza trainante per noi',
  'case_type': 'Tipo di caso',
  'select_scam_type': 'Seleziona tipo di truffa',
  'your_case': 'Il tuo caso',
  'please_enter_feedback': 'Inserisci il tuo feedback sul caso',
  'your_phone': 'Il tuo numero di cellulare',
  'please_enter_your_phone': 'Inserisci il tuo numero di telefono',
  'submit_click': 'Invia con un clic',
  'please_enter_phone': 'Inserisci il numero di telefono',
  'search': 'Cerca',
  'no_relevant': 'Nessuna risposta rilevante trovata',
  'success_title': 'Storie di successo classiche',
  'bot_tele': 'Telefono',
  'bot_address': 'Indirizzo',
  'bot_cooperation': 'Agenzia di autorizzazione alla cooperazione',
  'quick_links': 'Link veloci',
  'CFTC': 'Regolatori finanziari internazionali tra cui la Commodity and Futures Trading Commission (CFTC)',
  'FINRA': 'FINRA (FINRA)',
  'FINMA': 'Autorità di vigilanza sui mercati finanziari svizzeri (FINMA)',
  'FCA': 'Financial Conduct Authority del Regno Unito (FCA)',
  'HKMA': 'Autorità monetaria di Hong Kong (HKMA)',
  'CFPB': 'Consumer Financial Protection Bureau (CFPB)',
  'case_option1': 'Truffa alla raccolta fondi su Internet',
  'case_option2': 'Frode sugli investimenti in fondi comuni su Internet',
  'case_option3': 'Truffa con criptovalute',
  'case_option4': 'Truffa sentimentale',
  'case_option5': "Truffa nell'e-commerce",
  'case_option6': 'Truffa lavorativa part-time',
  'case_option7': 'Altre truffe',
  'case_detail': 'Dettagli del caso',
  'reply_message': 'Messaggio di risposta',
  'submission_time': 'Ora di invio',
  ["home_service"]: "Servizio clienti",
}
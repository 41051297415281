<template>
  <div class="home-container">
    <header class="header">
      <div class="header-content">
        <div class="logo" @click="openLink">
          <img src="../assets/logo.svg" alt="" />
        </div>
        <div class="login-btn g-btn-purple" @click="openLink">
          {{ $t("login") }}
        </div>
      </div>
    </header>
    <div class="wrapper">
      <section class="daily-section">
        <div class="daily-section-content">
          <div class="daily-left-text">
            <h1>{{ $t("daily-eth") }}</h1>
            <div class="left-text-desc">
              {{ $t("world-first") }}
            </div>
            <div class="start-btn g-btn-purple" @click="openLink">
              {{ $t("start-earn") }}
            </div>
          </div>
          <div class="daily-right-img">
            <img src="../assets/anim.jpg" alt="" />
          </div>
        </div>
      </section>
      <section class="how-section">
        <div class="how-section-content">
          <h2>{{ $t("how-daily") }}</h2>
          <div class="how-block">
            <div class="how-block-item">
              <img src="../assets/ic01_purple.svg" alt="" />
              <h4>{{ $t("signup") }}</h4>
              <p>{{ $t("easi-signup") }}</p>
            </div>
            <div class="how-block-item">
              <img src="../assets/ic02_purple.svg" alt="" />
              <h4>{{ $t("virtual-miner") }}</h4>
              <p>{{ $t("choose-virtual-miner") }}</p>
            </div>
            <div class="how-block-item">
              <img src="../assets/ic03_purple.svg" alt="" />
              <h4>{{ $t("dialy-returns") }}</h4>
              <p>{{ $t("dialy-eth-returns") }}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="manuals">
        <div class="manuals-container">
          <h2>{{ $t("how-to-start") }}</h2>
          <div class="manuals-wrap">
            <img
              class="manuals-border"
              src="../assets/border-gradient.svg"
              alt=""
            />
            <div class="manuals-row">
              <div class="manuals-number">1</div>
              <div class="manuals-text">
                {{ $t("1-start-1") }}<br />{{ $t("1-start-2") }}
              </div>
            </div>
            <div class="manuals-row">
              <div class="manuals-number">2</div>
              <div class="manuals-text">
                {{ $t("2-start-1") }}<br />{{ $t("2-start-2") }}
              </div>
            </div>
            <div class="manuals-row">
              <div class="manuals-number">3</div>
              <div class="manuals-text">
                {{ $t("3-start-1") }}<br />{{ $t("3-start-2") }}
              </div>
            </div>
          </div>
          <img
            class="manuals-phone"
            width="473px"
            src="../assets/manuals_phone.webp"
            alt=""
          />
          <img
            class="manuals-bg"
            src="../assets/gradient_ellipse.webp"
            alt=""
          />
          <img
            class="manuals-bg-mob"
            src="../assets/gradient_ellipse_mob.webp"
            alt=""
          />
        </div>
      </section>
      <section class="explore">
        <div class="explore-container">
          <h2>{{ $t("explore-the-gomining") }}</h2>
          <div class="explore-content">
            <div class="explore-content-item">
              <h4>{{ $t("explore-1-1") }}<br />{{ $t("explore-1-2") }}</h4>
              <img src="../assets/education-ic.webp" alt="" />
            </div>
            <div class="explore-content-item">
              <h4>{{ $t("explore-2-1") }}<br />{{ $t("explore-2-2") }}</h4>
              <img src="../assets/market-ic.webp" alt="" />
            </div>
            <div class="explore-content-item">
              <h4>{{ $t("explore-3-1") }}<br />{{ $t("explore-3-2") }}</h4>
              <img src="../assets/game-ic.webp" alt="" />
            </div>
          </div>
          <div class="btn-bar">
            <div class="start-btn g-btn-purple" @click="openLink">
              {{ $t("start-earn") }}
            </div>
          </div>
        </div>
      </section>
      <section class="home-section6">
        <div class="home-section6-container">
          <h2>{{ $t("invest-safely-1") }}<br />{{ $t("invest-safely-2") }}</h2>
          <p>
            {{ $t("invest-safely-tip-1") }}<br />{{ $t("invest-safely-tip-2")
            }}<br />{{ $t("invest-safely-tip-3") }}
          </p>
          <div class="note-text">
            <img class="world-icon" src="../assets/world-ic.svg" alt="" />
            {{ $t("secure-reliable") }}
          </div>
          <div class="number-bar">
            <div class="number-bar-item">
              <h3>3,300,000 TH/s</h3>
              <p>{{ $t("amount-hashrate") }}</p>
            </div>
            <div class="number-bar-item">
              <h3>$55,000,000</h3>
              <p>{{ $t("total-sales") }}</p>
            </div>
          </div>
          <img class="map-img" src="../assets/map.webp" alt="" />
        </div>
      </section>
      <section class="home-section7">
        <div class="home-section7-container">
          <h2>
            {{ $t("easily-deposit-1") }}<br />{{ $t("easily-deposit-2") }}
          </h2>
          <p>
            {{ $t("no-commission") }}
          </p>
          <div class="withdraw-wrap">
            <img src="../assets/visa-ic.svg" alt="" class="payment-icon" />
            <img
              src="../assets/master-card-ic.svg"
              alt=""
              class="payment-icon"
            />
            <img src="../assets/apple-pay-ic.svg" alt="" class="payment-icon" />
            <img src="../assets/bitcoin-ic.svg" alt="" class="payment-icon" />
            <img
              src="../assets/google-pay-ic.svg"
              alt=""
              class="payment-icon"
            />
            <img src="../assets/tether-ic.svg" alt="" class="payment-icon" />
            <img src="../assets/gomining-ic.svg" alt="" class="payment-icon" />
          </div>
        </div>
      </section>
      <section class="home-section8">
        <div class="home-section8-container">
          <h2>
            {{ $t("get-start-free-1") }}<br />{{ $t("get-start-free-2") }}
          </h2>
          <p>{{ $t("start-earn-eth") }}</p>
          <div class="start-btn g-btn-purple" @click="openLink">
            {{ $t("start-earn-today") }}
          </div>
          <img
            class="section8bg"
            width="767px"
            src="../assets/section_8_bg.webp"
            alt=""
          />
          <img class="section8img" src="../assets/section_8_img.webp" alt="" />
          <img
            class="section8imgmob"
            src="../assets/section_8_mob.webp"
            alt=""
          />
        </div>
      </section>
      <footer class="footer">
        <div class="footer-container">
          <div class="footer-left">
            <div class="logo" @click="openLink">
              <img src="../assets/logo-footer.svg" alt="" />
            </div>
            <div class="left-item x" @click="openLink">
              X<img src="../assets/arrow2.svg" alt="" />
            </div>
            <div class="left-item" @click="openLink">
              DISCORD<img src="../assets/arrow2.svg" alt="" />
            </div>
          </div>
          <div class="footer-right">
            <p>{{ $t("bot-1") }}<br />{{ $t("bot-2") }}</p>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      link: ''
    }
  },
  mounted() {
    this.link = process.env.VUE_APP_OPEN_LINK
  },
  methods: {
    openLink() {
      window.open(this.link)
    }
  }
};
</script>

<style lang="scss" scoped>
.g-btn-purple {
  cursor: pointer;
  color: #fff;
  background: linear-gradient(to right, #c97ef8, #7540ef);
}
.g-btn-purple:focus-visible,
.g-btn-purple:hover {
  background: linear-gradient(to right, #7540ef, #c97ef8);
}
.home-container {
  .header {
    padding: 23px 0;
    backdrop-filter: blur(20px);
    border-bottom: 1px solid #e4e4f0;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 999;
    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1310px;
      margin-right: auto;
      margin-left: auto;
      padding: 0 15px;
      width: 100%;
      box-sizing: border-box;
      .logo {
        cursor: pointer;
        img {
          width: 156px;
        }
        @media screen and (max-width: 575px) {
          img {
            width: 141px;
          }
        }
      }
      .login-btn {
        line-height: 1;
        padding: 13px 40px;
        color: #fff;
        border-radius: 30px;
        font-size: 20px;
        display: inline-flex;
        background: linear-gradient(to right, #c97ef8, #7540ef);
        &:hover {
          background: linear-gradient(to right, #7540ef, #c97ef8);
        }
      }
    }
    @media screen and (max-width: 1199px) {
      .header-content {
        max-width: 994px;
      }
    }
    @media screen and (max-width: 1023px) {
      .header-content {
        max-width: 100%;
      }
    }
  }
  .wrapper {
    .daily-section {
      padding: 54px 0 60px 0;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      height: 627px;

      &-content {
        max-width: 1310px;
        margin-right: auto;
        margin-left: auto;
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;
        .daily-left-text {
          max-width: 675px;
          color: #363843;
          font-size: 35px;
          line-height: 120%;
          h1 {
            max-width: 675px;
            font-weight: normal;
            font-size: 60px;
            line-height: 110%;
            margin: 0 0 25px;
            padding: 0;
            color: #7540ef;
          }
          @media screen and (max-width: 1199px) {
            h1 {
              font-size: 50px;
            }
          }
          @media screen and (max-width: 1023px) {
            h1 {
              font-size: 45px;
            }
          }
          @media screen and (max-width: 575px) {
            h1 {
              line-height: 120%;
              font-size: 36px;
              margin: 0 0 15px;
            }
          }
        }
        @media screen and (max-width: 1199px) {
          .daily-left-text {
            padding-right: 30px;
            width: calc(100% - 400px);
            font-size: 30px;
          }
        }
        @media screen and (max-width: 1023px) {
          .daily-left-text {
            padding: 0 0 60px;
            text-align: center;
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            font-size: 30px;
          }
        }
        .left-text-desc {
          margin: 0 0 20px;
          max-width: 490px;
        }
        @media screen and (max-width: 575px) {
          .left-text-desc {
            max-width: 270px;
            margin: 0 auto 26px;
          }
        }
        .start-btn {
          line-height: 1;
          padding: 13px 40px;
          color: #fff;
          border-radius: 30px;
          font-size: 20px;
          display: inline-flex;
          background: linear-gradient(to right, #c97ef8, #7540ef);
        }
        .daily-right-img {
          position: absolute;
          bottom: 0;
          left: calc(50% + 185px);
          width: 342px;
          img {
            width: 100%;
          }
        }
        @media screen and (max-width: 1199px) {
          .daily-right-img {
            right: 50px;
            left: auto;
          }
        }
        @media screen and (max-width: 1023px) {
          .daily-right-img {
            width: 290px;
            position: relative;
            display: block;
            margin: 0 auto;
            right: auto;
            left: auto;
            bottom: auto;
          }
        }
      }
      @media screen and (max-width: 1199px) {
        .content {
          max-width: 994px;
        }
      }
      @media screen and (max-width: 1023px) {
        .content {
          display: block;
          max-width: 100%;
        }
      }
    }
    @media screen and (max-width: 1023px) {
      .daily-section {
        height: auto;
        padding-top: 50px;
        padding-bottom: 0;
      }
    }
    @media screen and (max-width: 575px) {
      .daily-section {
        display: block;
        padding: 51px 0 0px;
        box-sizing: border-box;
        text-align: center;
        height: auto;
        margin-top: 0;
      }
    }

    .how-section {
      padding: 115px 0 123px;
      background: #fafafd;
      &-content {
        max-width: 1310px;
        margin-right: auto;
        margin-left: auto;
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;
        h2 {
          font-size: 50px;
          margin-bottom: 45px;
          text-align: center;
        }
        @media screen and (max-width: 1299px) {
          h2 {
            font-size: 44px;
          }
        }
        @media screen and (max-width: 1199px) {
          h2 {
            font-size: 36px;
          }
        }
        @media screen and (max-width: 1023px) {
          h2 {
            font-size: 30px;
          }
        }
        .how-block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 5px;
          margin-bottom: 40px;
          &-item {
            padding: 40px 30px 40px;
            width: 414px;
            background: #fff;
            border-radius: 20px;
            text-align: center;
            img {
              width: 100px;
              margin: 0 0 10px;
            }
            h4 {
              margin-bottom: 8px;
              text-align: center;
              font-size: 28px;
            }
            @media screen and (max-width: 767.98px) {
              h4 {
                font-size: 22px;
              }
            }
            @media screen and (max-width: 575px) {
              h4 {
                font-size: 28px;
              }
            }
            p {
              max-width: 325px;
              margin-left: auto;
              margin-right: auto;
              display: table;
              font-size: 16px;
              line-height: 140%;
            }
            @media screen and (max-width: 767.98px) {
              p {
                font-size: 14px;
              }
            }
            @media screen and (max-width: 575px) {
              p {
                font-size: 16px;
                margin-top: 6px;
              }
            }
          }
        }
        @media screen and (max-width: 1023px) {
          .how-block {
            justify-content: center;
            flex-wrap: wrap;
          }
        }
      }
      @media screen and (max-width: 1199px) {
        .how-section-content {
          max-width: 994px;
          h4 {
            font-size: 24px;
          }
        }
      }
    }
    @media screen and (max-width: 1023px) {
      .how-section {
        padding: 80px 0 60px;
      }
      .how-section-content {
        max-width: 100%;
      }
    }
    @media screen and (max-width: 767.98px) {
      .how-section {
        padding: 75px 0 80px;
        background: #fafafd;
      }
      .how-section-content {
        max-width: 100%;
      }
    }

    .manuals {
      color: #fff;
      overflow: hidden;
      padding-top: 113px;
      position: relative;
      padding-bottom: 120px;
      background-color: #101021;
      &-container {
        margin-right: auto;
        margin-left: auto;
        padding: 0 15px;
        width: 100%;
        max-width: 1092px;
        box-sizing: border-box;
        h2 {
          color: #fff;
          z-index: 1;
          position: relative;
          text-align: left;
          margin-bottom: 47px;
          font-size: 50px;
          font-weight: normal;
        }
        @media screen and (max-width: 1299px) {
          h2 {
            font-size: 44px;
          }
        }
        @media screen and (max-width: 1199px) {
          h2 {
            font-size: 36px;
          }
        }
        @media screen and (max-width: 1023px) {
          h2 {
            text-align: center;
            font-size: 30px;
          }
        }
      }
      .manuals-wrap {
        position: relative;
        z-index: 1;
        .manuals-border {
          top: 11px;
          left: 43px;
          position: absolute;
        }
        @media screen and (max-width: 1023px) {
          .manuals-border {
            top: 15px;
            left: 23px;
            width: 2px;
          }
        }
        img {
          max-width: 100%;
        }
        .manuals-row {
          z-index: 1;
          display: flex;
          column-gap: 20px;
          position: relative;
          align-items: center;
          .manuals-number {
            width: 90px;
            height: 90px;
            display: flex;
            flex: 0 0 90px;
            font-size: 40px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            background-color: #c97ef8;
            &:nth-child(2) {
              background-color: #b46ff6;
            }
            &:nth-child(3) {
              background-color: #864df1;
            }
          }
          .manuals-text {
            font-size: 28px;
            line-height: 1.2;
            padding-bottom: 5px;
          }
          @media screen and (max-width: 1023px) {
            .manuals-number {
              width: 48px;
              height: 48px;
              font-size: 22px;
              flex: 0 0 48px;
            }
            .manuals-text {
              font-size: 16px;
            }
          }
        }
        .manuals-row:not(:last-child) {
          margin-bottom: 40px;
        }

        @media screen and (max-width: 1023px) {
          .manuals-row {
            column-gap: 10px;
          }
          .manuals-row:not(:last-child) {
            margin-bottom: 19px;
          }
          .manuals-number {
            width: 48px;
            height: 48px;
            font-size: 22px;
            flex: 0 0 48px;
          }
          .manuals-text {
            font-size: 16px;
          }
          .manuals-row br {
            display: none;
          }
        }
      }
      .manuals-phone {
        left: 50%;
        position: absolute;
        transform: translateX(7%);
        z-index: 1;
        top: 67px;
      }
      .manuals-bg {
        left: 50%;
        bottom: 0;
        position: absolute;
        transform: translateX(-30%);
        max-width: none;
      }
      .manuals-bg-mob {
        display: none;
      }
      @media screen and (max-width: 1023px) {
        .manuals-wrap {
          max-width: 343px;
          margin: 0 auto;
        }
        .manuals-phone {
          width: 330px;
          margin: 0 auto;
          display: block;
          z-index: 1;
          position: relative;
          transform: none;
          left: auto;
          top: auto;
        }
        .manuals-bg {
          display: none;
        }
        .manuals-bg-mob {
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          display: block;
          max-width: none;
          width: 500px;
          bottom: 10px;
        }
      }
    }
    @media screen and (max-width: 1023px) {
      .manuals {
        padding-top: 75px;
        padding-bottom: 60px;
      }
    }
    .explore {
      margin-top: 0 !important;
      padding: 114px 0 120px;
      position: relative;
      background: #fafafd;
      &-container {
        max-width: 1310px;
        margin-right: auto;
        margin-left: auto;
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;
        h2 {
          margin-bottom: 40px;
          font-size: 50px;
          text-align: center;
        }
        @media screen and (max-width: 1299px) {
          h2 {
            font-size: 44px;
          }
        }
        @media screen and (max-width: 1199px) {
          h2 {
            font-size: 36px;
          }
        }
        @media screen and (max-width: 1023px) {
          h2 {
            font-size: 30px;
          }
        }
        .explore-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 40px;
          &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 23px 20px 27px 40px;
            width: 414px;
            background: #fff;
            border-radius: 20px;
            box-sizing: border-box;
            h4 {
              text-align: left;
              font-size: 28px;
            }
            img {
              width: 165px;
              height: 165px;
            }
            @media screen and (max-width: 1199px) {
              h4 {
                font-size: 24px;
              }
              img {
                width: 100px;
                height: 100px;
              }
            }
            @media screen and (max-width: 767.98px) {
              h4 {
                font-size: 18px;
              }
              img {
                width: 75px;
                height: 75px;
              }
            }
            @media screen and (max-width: 575px) {
              h4 {
                font-size: 24px;
              }
              img {
                width: 117px;
                height: 117px;
              }
            }
          }

          @media screen and (max-width: 1299px) {
            .explore-content-item {
              width: 32%;
            }
          }
          @media screen and (max-width: 1023px) {
            .explore-content-item {
              width: 46%;
              margin: 0 2% 20px;
              max-width: 400px;
            }
          }
          @media screen and (max-width: 767.98px) {
            .explore-content-item {
              padding: 10px 20px 10px 40px;
            }
          }
          @media screen and (max-width: 575px) {
            .explore-content-item {
              padding: 16px 20px;
              margin: 0 0 20px;
              width: 100%;
            }
          }
        }
        .btn-bar {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          .start-btn {
            line-height: 1;
            padding: 13px 40px;
            color: #fff;
            border-radius: 30px;
            font-size: 20px;
            display: inline-flex;
          }
        }
        @media screen and (max-width: 1023px) {
          .explore-content {
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 20px;
          }
        }
      }
      @media screen and (max-width: 1199px) {
        .explore-container {
          max-width: 994px;
        }
      }
    }
    @media screen and (max-width: 1023px) {
      .explore {
        padding-top: 77px;
        padding-bottom: 78px;
        &-container {
          max-width: 100%;
        }
      }
    }
    .home-section6 {
      padding: 115px 0 57px;
      color: #fff;
      background-color: #101021;
      &-container {
        max-width: 1310px;
        margin-right: auto;
        margin-left: auto;
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;
        color: #fff;
        h2 {
          font-style: normal;
          font-size: 50px;
          text-align: center;
          margin-bottom: 22px;
        }
        p {
          text-align: center;
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 140%;
        }
        .note-text {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          line-height: 120%;
          text-align: center;
          img {
            margin-right: 10px;
          }
        }
        .number-bar {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 35px;
          &-item {
            background: linear-gradient(88.75deg, #c97ef8 0%, #7540ef 100%);
            border-radius: 10px;
            width: 413px;
            text-align: center;
            color: #fff;
            margin: 0 20px;
            padding: 20px 0 28px;
            box-sizing: border-box;
            h3 {
              margin-bottom: 11px;
              color: #fff;
              font-size: 40px;
            }
            p {
              font-size: 16px;
              line-height: 140%;
            }
            @media screen and (max-width: 1299px) {
              h3 {
                font-size: 32px;
              }
            }
            @media screen and (max-width: 1199px) {
              h3 {
                font-size: 29px;
              }
            }
            @media screen and (max-width: 1023px) {
              h3 {
                font-size: 25px;
              }
            }
            @media screen and (max-width: 767.98px) {
              h3 {
                font-size: 26px;
              }
            }
          }
        }
        .map-img {
          margin-top: 44px;
          max-width: 100%;
        }
        @media screen and (max-width: 575px) {
          .number-bar {
            flex-direction: column;
            flex-wrap: wrap;
            &-item {
              margin-left: auto;
              margin-right: auto;
              width: 90%;
              margin-bottom: 20px;
            }
          }
        }
        @media screen and (max-width: 1299px) {
          h2 {
            font-size: 44px;
          }
        }
        @media screen and (max-width: 1199px) {
          h2 {
            font-size: 36px;
          }
        }
        @media screen and (max-width: 1023px) {
          h2 {
            font-size: 30px;
          }
        }
      }
      @media screen and (max-width: 1199px) {
        .home-section6-container {
          max-width: 994px;
        }
      }
    }
    @media screen and (max-width: 1023px) {
      .home-section6 {
        padding: 65px 0 57px;
        &-container {
          max-width: 100%;
        }
        br {
          display: none !important;
        }
      }
    }
    @media screen and (max-width: 767.98px) {
      .home-section6 {
        padding: 80px 0;
      }
    }
    .home-section7 {
      padding: 115px 0 120px;
      background-color: #fafafd;
    }
    @media screen and (max-width: 1023px) {
      .home-section7 {
        padding: 60px 0;
        background-color: #fafafd;
      }
    }
    @media screen and (max-width: 575px) {
      .home-section7 {
        padding: 75px 0 62px;
      }
    }
    .home-section7 {
      padding: 115px 0 120px;
      background-color: #fafafd;
      &-container {
        max-width: 1310px;
        margin-right: auto;
        margin-left: auto;
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;
        h2 {
          margin-bottom: 22px;
          font-style: normal;
          font-size: 50px;
          text-align: center;
        }
        p {
          text-align: center;
          font-size: 16px;
          line-height: 140%;
        }
        .withdraw-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 40px;
          img {
            max-width: 100%;
          }
          @media screen and (max-width: 767.98px) {
            .payment-icon {
              width: 18%;
            }
          }
        }
        @media screen and (max-width: 1299px) {
          h2 {
            font-size: 44px;
          }
          .payment-icon {
            width: 155px;
          }
        }
        @media screen and (max-width: 1199px) {
          h2 {
            font-size: 36px;
          }
          .payment-icon {
            width: 125px;
          }
        }
        @media screen and (max-width: 1023px) {
          h2 {
            font-size: 30px;
          }
          .withdraw-wrap {
            max-width: 738px;
            margin: 40px auto 0;
            justify-content: center;
            flex-wrap: wrap;
            .payment-icon {
              width: 18%;
              margin: 0 1.5% 20px;
            }
          }
        }
        @media screen and (max-width: 575px) {
          h2 br {
            display: none;
          }
          p {
            font-size: 12px;
          }
          .payment-icon {
            width: 22%;
            margin: 0 1.5% 19px;
          }
        }
      }
      @media screen and (max-width: 1199px) {
        .home-section7-container {
          max-width: 994px;
        }
      }
    }
    @media screen and (max-width: 1023px) {
      .home-section7 {
        padding: 60px 0;
        background-color: #fafafd;
        &-container {
          max-width: 100%;
        }
      }
    }
    @media screen and (max-width: 575px) {
      .home-section7 {
        padding: 75px 0 62px;
      }
    }
    .home-section8 {
      overflow: hidden;
      background: #101021;
      position: relative;
      box-sizing: border-box;
      padding: 115px 0 122px;
      &-container {
        max-width: 1310px;
        margin-right: auto;
        margin-left: auto;
        padding: 0 15px;
        width: 100%;
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        color: #fff;
        h2 {
          margin-bottom: 23px;
          text-align: left;
          font-size: 50px;
        }
        p {
          margin-bottom: 18px;
          font-size: 16px;
          line-height: 140%;
        }
        .start-btn {
          line-height: 1;
          padding: 13px 40px;
          color: #fff;
          border-radius: 30px;
          font-size: 20px;
          display: inline-flex;
          background: linear-gradient(to right, #c97ef8, #7540ef);
        }
        .section8bg {
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-29%, -50%);
          max-width: none;
        }
        .section8img {
          position: absolute;
          left: 50%;
          top: 50%;
          max-width: 39vw;
          transform: translate(-2%, -50%);
          width: 654px;
        }
        .section8imgmob {
          display: none;
        }
        @media screen and (max-width: 1299px) {
          h2 {
            font-size: 44px;
          }
        }
        @media screen and (max-width: 1199px) {
          h2 {
            font-size: 36px;
          }
        }
        @media screen and (max-width: 1023px) {
          h2 {
            text-align: center;
            font-size: 30px;
          }
          p {
            text-align: center;
          }
          .section8bg {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: none;
            width: 570px;
          }
          .section8img {
            display: none;
          }
          .section8imgmob {
            display: block;
            width: 464px;
            max-width: none;
            position: relative;
            transform: translate(-50%);
            left: 50%;
            margin-top: 38px;
          }
        }
        @media screen and (max-width: 767.98px) {
          h2 {
            font-size: 36px;
            margin-bottom: 20px;
          }
        }
        @media (min-width: 576px) {
          h2 {
            font-size: 30px;
            margin-bottom: 24px;
          }
          p {
            margin-bottom: 21px;
            font-size: 12px;
          }
        }
      }
    }
    @media screen and (max-width: 1199px) {
      .home-section8 {
        padding: 70px 0;
        &-container {
          max-width: 994px;
        }
      }
    }
    @media screen and (max-width: 1023px) {
      .home-section8 {
        padding: 65px 0;
        background-image: none;
        text-align: center;
        padding-bottom: 40px;
        &-container {
          max-width: 100%;
        }
      }
      .home-section8::before {
        content: "";
        display: block;
        width: 438px;
        height: 438px;
        position: absolute;
        border-radius: 438px;
        background: linear-gradient(
          135.04deg,
          rgba(105, 40, 255, 0.8) 1.68%,
          rgba(235, 125, 175, 0.8) 72.81%,
          rgba(240, 125, 125, 0.8) 91.78%
        );
        filter: blur(77.5389px);
        left: 50%;
        margin-left: -219px;
        bottom: 65px;
      }
    }
    @media screen and (max-width: 575px) {
      .home-section8 {
        padding: 75px 0 80px;
      }
    }
    .footer {
      padding: 40px 0;
      background-color: #101021;
      &-container {
        max-width: 1310px;
        margin-right: auto;
        margin-left: auto;
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .footer-left {
          display: flex;
          align-items: center;
          .logo {
            padding-right: 15px;
            padding-left: 15px;
            cursor: pointer;
          }
          .left-item {
            display: flex;
            align-items: center;
            color: #fff;
            cursor: pointer;
            img {
              transition: transform 0.3s;
            }
            &:hover {
              img {
                transition: transform 0.3s;
                transform: rotate(45deg);
              }
            }
          }
          .x {
            margin-left: 50px;
            margin-right: 38px;
          }
        }
        .footer-right {
          color: #fff;
          font-size: 10px;
          line-height: 120%;
          max-width: 345px;
          color: #fff;
        }
      }
    }
    @media screen and (max-width: 1199px) {
      .footer-container {
        max-width: 994px;
      }
    }
    @media screen and (max-width: 1023px) {
      .footer-container {
        max-width: 100%;
      }
    }
    @media screen and (max-width: 767.98px) {
      .footer-container {
        max-width: 100%;
        flex-direction: column;
        align-items: flex-start;
        .footer-left {
          flex-direction: column;
          align-items: flex-start;
          .left-item {
            width: 100%;
            justify-content: flex-start;
            padding-left: 15px;
            margin-top: 5px;
          }
          .x {
            margin: 0;
            margin-top: 15px;
          }
        }
        .footer-right {
          padding-left: 15px;
          margin-top: 15px;
        }
      }
    }
  }
}
</style>
